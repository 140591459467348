import { useEffect, useRef } from 'react'
import { ethers } from "ethers";
import axios from "axios";
import moment from 'moment';
import { toast } from 'react-toastify';
import AlertService from '../services/alertService';
import DeviceService from '../services/deviceService';
import {
    RENEW_PAIR_CODE_SUCCESS, RENEW_PAIR_CODE_ERROR, DELETE_DEVICE_SUCCESS, DELETE_DEVICE_ERROR,
    UPDATE_ACCESS_STATUS_SUCCESS, UPDATE_ACCESS_STATUS_ERROR, SHOW_PAIR_CODE
} from '../actions/types/deviceActionTypes';
import { SET_DUMMY_DATA, CATEGORIZE_DUMMY_DATA } from '../actions/types/reportActionTypes';
import { DELETE_ALERT_SUCCESS, DELETE_ALERT_ERROR, RESEND_MAIL_SUCCESS, RESEND_ALL_MAILS_SUCCESS, RESEND_ALL_MAILS_ERROR } from '../actions/types/alertActionTypes';
import { DeviceContractAddress } from "../config";
import DeviceAbi from '../dapp/build/contracts/DeviceContract.json';
import { NORMALIZE_ALARM_CODES, NORMALIZE_PATIENT_CODES, NORMALIZE_PATTERN_CODES } from "./constants";

export const showPairCode = (device, dispatch) => {
    navigator.clipboard.writeText(device);
    dispatch({ type: SHOW_PAIR_CODE, payload: 'Pair Code Copied Successfully' })
}

export const renewPairCode = (device, auth, dispatch, setUpdateRequired) => (
    DeviceService.renewPairCode(device, auth).then((devices) => {
        dispatch({ type: RENEW_PAIR_CODE_SUCCESS, payload: devices.data });
        setUpdateRequired(true);
    }).catch((err) => {
        dispatch({ type: RENEW_PAIR_CODE_ERROR, payload: err });
    })
)

export const updateAccessStatus = (device, auth, currentStatus, dispatch, setUpdateRequired) => (
    DeviceService.updateAccessStatus(device, currentStatus, auth).then((devices) => {
        dispatch({ type: UPDATE_ACCESS_STATUS_SUCCESS, payload: devices.data });
        setUpdateRequired(true);
    }).catch((err) => {
        dispatch({ type: UPDATE_ACCESS_STATUS_ERROR, payload: err });
    })
)

export const deleteDevice = (device, auth, dispatch, setUpdateRequired) => (
    DeviceService.deleteDevice(device, auth).then((devices) => {
        dispatch({ type: DELETE_DEVICE_SUCCESS, payload: devices.data });
        setUpdateRequired(true);
    }).catch((err) => {
        dispatch({ type: DELETE_DEVICE_ERROR, payload: err });
    })
)

export const getAllPairedDevices = async () => {
    try {
        const { ethereum } = window;
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum)
            const signer = provider.getSigner();
            const DeviceContract = new ethers.Contract(
                DeviceContractAddress,
                DeviceAbi.abi,
                signer
            )

            let allPairedDevices = await DeviceContract.getMyDevices();
            return allPairedDevices;
        }
    } catch (error) {
        console.log('err', error);
    }
}

export const getTimeDiff = (a, b) => (a.diff(b, 'seconds'));

const alarmProcessor = (tempAlarmObjects) => {
    let alarmObjects = [];
    let alarmGroup = 0;

    tempAlarmObjects.forEach((temp, index) => {
        const currTimeObj = moment(temp?.DateTime, 'DD/MM/YYYY hh:mm:ss');
        const currAlarmCode = temp?.Alarm;
        const nextAlarmCode = tempAlarmObjects[index + 1]?.Alarm;
        const nextTimeObj = moment(tempAlarmObjects[index + 1]?.DateTime, 'DD/MM/YYYY hh:mm:ss');
        const timeDiff = Math.abs(getTimeDiff(currTimeObj, nextTimeObj))
        if (timeDiff < 60 * 5) {
            if (currAlarmCode === nextAlarmCode) {
                alarmObjects = [
                    ...alarmObjects,
                    {
                        DateTime: temp.DateTime,
                        alarmCode: temp.Alarm,
                        alarmType: NORMALIZE_ALARM_CODES[temp.Alarm],
                        alarmGroup: alarmGroup
                    }
                ];
            } else {
                alarmGroup++;
            }
        } else {
            alarmGroup++;
        }
    });

    const finalAlarmObject = alarmObjects.reduce((x, y) => {
        (x[y.alarmGroup] = x[y.alarmGroup] || []).push(y);
        return x;
    }, {});

    return finalAlarmObject;
}

const parameterProcessor = (tempParameterObjects) => {
    let parameterObjects = [];
    let parameterGroup = 0;

    tempParameterObjects.forEach((temp, index) => {
        const currTimeObj = moment(temp?.DateTime, 'DD/MM/YYYY hh:mm:ss');
        const nextTimeObj = moment(tempParameterObjects[index + 1]?.DateTime, 'DD/MM/YYYY hh:mm:ss');
        const timeDiff = Math.abs(getTimeDiff(currTimeObj, nextTimeObj))
        if (timeDiff < 60 * 10) {
            parameterObjects = [
                ...parameterObjects,
                {
                    DateTime: temp.DateTime,
                    patameters: temp.Parameters,
                    parameterGroup: parameterGroup
                }
            ];
        } else {
            parameterGroup++;
        }
    });

    const finalParameterObject = parameterObjects.reduce((x, y) => {
        (x[y.parameterGroup] = x[y.parameterGroup] || []).push(y);
        return x;
    }, {});

    return Object.values(finalParameterObject);
}

export const processDummyData = (rawData, dispatch) => {
    const tempAlarmObjects = Object.values(rawData).filter(r => Object.keys(r).includes('Alarm')).filter(tmp => tmp.Alarm !== 0);
    const PauseObjects = Object.values(rawData).filter(r => Object.keys(r).includes('Pause'));
    const tempParameterObjects = Object.values(rawData).filter(r => Object.keys(r).includes('Parameters'));
    const finalAlarmObject = alarmProcessor(tempAlarmObjects);
    const finalParameterObject = parameterProcessor(tempParameterObjects);
    return dispatch({ type: CATEGORIZE_DUMMY_DATA, finalAlarmObject, PauseObjects, finalParameterObject });
};

export const getDummyData = async (dispatch) => {
    const { data } = await axios.get(`https://ciphermed.app/logs/dummyLogs.json`);
    processDummyData(data, dispatch);
    dispatch({ type: SET_DUMMY_DATA, payload: data });
};

export const getAlarmDetails = selector => {
    const isSingleInstance = selector.length === 1;
    const firstSeen = selector[0].DateTime;
    const lastSeen = selector.at(-1).DateTime;
    const alarmType = selector[0].alarmType;
    const duration = getTimeDiff(moment(lastSeen, 'DD/MM/YYYY hh:mm:ss'), moment(firstSeen, 'DD/MM/YYYY hh:mm:ss'));
    return { isSingleInstance, firstSeen, lastSeen, alarmType, duration };
}

export const getParameterDetails = selector => {
    if (selector.length > 0) {
        const firstSeen = selector[0][0]?.DateTime;
        const lastSeen = selector.at(-1)[0]?.DateTime;
        const parameterKeys = selector[0][0]?.patameters;
        const instanceSize = Object.values(selector).length;
        const pattern = NORMALIZE_PATTERN_CODES[selector.at(-1)[0]?.patameters?.pattern];
        const patient = NORMALIZE_PATIENT_CODES[selector.at(-1)[0]?.patameters?.patient];
        return { firstSeen, lastSeen, parameterKeys, instanceSize, pattern, patient };
    }
}

export const displayInfoToast = (message) => {
    toast.info(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: false,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "dark"
    })
}

export const displayErrorToast = (message) => {
    toast.error(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "dark"
    })
}

const baseAlarm = (date, alarmType) => ({ DateTime: date, Alarm: alarmType })
const basePause = (date, pauseType) => ({ DateTime: date, Pause: pauseType })
const baseParameter = (date, paramaterSet) => ({
    DateTime: date,
    Parameters: {
        "alarm": paramaterSet.alarm,
        "apneTime": paramaterSet.apneTime,
        "batteryLevel": paramaterSet.batteryLevel,
        "cycleTime": paramaterSet.cycleTime,
        "eSense": paramaterSet.eSense,
        "iSense": paramaterSet.iSense,
        "ieRatio": "1:2.0",
        "locked": 0,
        "maxPinsp": paramaterSet.maxPinsp,
        "maxRate": paramaterSet.maxRate,
        "maxVTidal": paramaterSet.maxVTidal,
        "minPinsp": paramaterSet.minPinsp,
        "minRate": paramaterSet.minRate,
        "minVTidal": paramaterSet.minVTidal,
        "mode": paramaterSet.mode,
        "patient": paramaterSet.patient,
        "pattern": paramaterSet.pattern,
        "paused": (Math.floor(Math.random() * 2) + 1) % 2 === 0,
        "peep": paramaterSet.peep,
        "pinsp": paramaterSet.pinsp,
        "psup": paramaterSet.psup,
        "rate": paramaterSet.rate,
        "ratio": paramaterSet.ratio,
        "riseTime": paramaterSet.riseTime,
        "server": true,
        "socketConnected": true,
        "tExp": paramaterSet.tExp,
        "tInsp": paramaterSet.tInsp,
        "vTidal": paramaterSet.vTidal,
    }
})

const getRandomFromList = (list) => {
    return list[Math.floor((Math.random() * list.length))];
}

const randomWithMultiplier = (min, max, multiplier) => {
    return Math.round((Math.random() * (max - min) + min) / 10) * multiplier;
}

const randomNumber = (min, max) => (Math.floor(Math.random() * max) + min);

const parameterGenerator = (patient, pattern) => {
    const paramaterSet = {
        alarm: getRandomFromList(Object.keys(NORMALIZE_ALARM_CODES)),
        apneTime: randomWithMultiplier(10, 60, 5),
        batteryLevel: randomWithMultiplier(5, 100, 5),
        cycleTime: randomNumber(1, 15),
        eSense: randomNumber(-8, 8),
        iSense: randomNumber(1, 8),
        maxPinsp: randomWithMultiplier(30, 60, 5),
        minPinsp: randomWithMultiplier(800, 1200, 5),
        maxRate: randomWithMultiplier(50, 120, 5),
        minRate: randomWithMultiplier(4, 50, 5),
        maxVTidal: randomWithMultiplier(800, 1200, 50),
        minVTidal: randomWithMultiplier(10, 600, 50),
        vTidal: randomWithMultiplier(10, 1200, 50),
        mode: randomNumber(1, 9),
        patient: patient,
        pattern: pattern,
        peep: randomNumber(1, 3),
        pinsp: randomWithMultiplier(10, 100, 5),
        psup: randomWithMultiplier(10, 100, 5),
        rate: randomWithMultiplier(10, 100, 5),
        ratio: 0.5,
        riseTime: 0,
        tExp: randomNumber(0, 10),
        tInsp: randomNumber(0, 10),
    }
    return paramaterSet;
}

const randomDateGenerator = (start, end) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const sortWithDate = (dataSet) => {
    return dataSet.sort((a, b) => {
        const compDate = moment(a.DateTime, 'DD.MM.YYYY hh:mm:ss').diff(moment(b.DateTime, 'DD.MM.YYYY hh:mm:ss'), 'seconds') < 0;
        if (compDate) {
            return -1;
        } else {
            return 1;
        }
    });
}

export const sortWithEpochDate = (dataSet, sortType) => {
    const multiplier = sortType.indexOf('Asc') > -1 ? 1 : -1;
    const target = sortType.indexOf('create') > -1 ? 'creation_date' : 'pair_date';
    return dataSet.sort((a, b) => {
        const compDate = moment.unix(a[target]).diff(moment.unix(b[target]), 'seconds') > 0;
        if (compDate) {
            return -1 * multiplier;
        } else {
            return 1 * multiplier;
        }
    });
}

export const generateDummyData = () => {
    const randomAlarmCount = randomNumber(100, 300);
    const randomPauseCount = randomNumber(40, 60);
    const randomParameterCount = randomNumber(1000, 1200);
    const patient = randomNumber(1, 3);
    const pattern = randomNumber(0, 2);
    let dummyData = [];
    for (let i = 0; i < randomAlarmCount; i++) {
        const date = randomDateGenerator(new Date(2023, 3, 5, 8), new Date(2023, 4, 5, 9));
        dummyData = [...dummyData, baseAlarm(moment(date).format('DD.MM.YYYY hh:mm:ss'), getRandomFromList(Object.keys(NORMALIZE_ALARM_CODES)))];
    }
    for (let i = 0; i < randomPauseCount; i++) {
        const date = randomDateGenerator(new Date(2023, 3, 5, 8), new Date(2023, 4, 5, 12));
        const randomBoolean = (Math.floor(Math.random() * 2) + 1) % 2 === 0;
        dummyData = [...dummyData, basePause(moment(date).format('DD.MM.YYYY hh:mm:ss'), randomBoolean)];
    }
    for (let i = 0; i < randomParameterCount; i++) {
        const date = randomDateGenerator(new Date(2023, 3, 5, 8), new Date(2023, 4, 5, 12));
        dummyData = [...dummyData, baseParameter(moment(date).format('DD.MM.YYYY hh:mm:ss'), parameterGenerator(patient, pattern))];
    }

    return sortWithDate(dummyData);
};

export const useOnClickOutsideRef = (callback, initialValue = null) => {
    const elementRef = useRef(initialValue)
    useEffect(() => {
        function handler(event) {
            if (!elementRef.current?.contains(event.target)) {
                callback()
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    }, [callback])
    return elementRef
}

export const isHexValid = (_hex) => (!['0x00'].includes(_hex));

export const deleteAlert = (alert, auth, dispatch) => (
    AlertService.deleteSingleAlert(alert, auth).then((alerts) => {
        dispatch({ type: DELETE_ALERT_SUCCESS, payload: alerts.data.pid });
    }).catch((err) => {
        dispatch({ type: DELETE_ALERT_ERROR, payload: err });
    })
)

export const resendSingleMail = (alert, auth, dispatch) => (
    AlertService.updateSingleMail(alert, auth).then((alerts) => {
        dispatch({ type: RESEND_MAIL_SUCCESS });
    })
)

export const resendAllMails = (alert, auth, dispatch) => (
    AlertService.updateAllPending(auth).then((alerts) => {
        dispatch({ type: RESEND_ALL_MAILS_SUCCESS });
    }).catch((err) => {
        dispatch({ type: RESEND_ALL_MAILS_ERROR, payload: err });
    })
)
