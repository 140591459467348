import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import {
	faUser,
	faKey,
	faEnvelope,
	faIdCard,
	faPhone,
	faLocationDot
} from '@fortawesome/free-solid-svg-icons';
import '../../App.css';
import UserService from '../../services/userService';
import { UPDATE_PROFILE_INFO_SUCCESS, UPDATE_PROFILE_INFO_ERROR } from '../../actions/types/uiActionTypes';
import { UPDATE_PROFILE_DETAILS } from '../../actions/types/authActionTypes';
import Badge from '../Common/Badge';

export default function ProfileSettings(props) {
	const { auth } = props;

	const [isFirstState, setIsFirstState] = useState(false);
	const dispatch = useDispatch();

	const handleClick = (userDetails) => {
		if (isFirstState) {
			UserService.createUser(userDetails, auth).then((singleDevice) => {
				dispatch({ type: UPDATE_PROFILE_INFO_SUCCESS });
				props.setIsOpen(false);
			}).catch((err) => {
				dispatch({ type: UPDATE_PROFILE_INFO_ERROR });
			})
		} else {
			UserService.updateUser(userDetails, auth).then((singleDevice) => {
				dispatch({ type: UPDATE_PROFILE_INFO_SUCCESS });
				props.setIsOpen(false);
			}).catch((err) => {
				dispatch({ type: UPDATE_PROFILE_INFO_ERROR });
			})
		}
	};

	const handleInputChange = (e) => {
		dispatch({ type: UPDATE_PROFILE_DETAILS, payload: { target: e.target.id, value: e.target.value } })
	}

	const handleRadioChange = (e) => {
		dispatch({ type: UPDATE_PROFILE_DETAILS, payload: { target: e.target.name, value: e.target.id } })
	}

	useEffect(() => {
		if (auth?.userDetails.location === '' || auth?.userDetails.user_name === '' || auth?.userDetails.user_mail === '' || auth?.userDetails.user_phone === '' || auth?.userDetails.user_type === null) {
			setIsFirstState(true);
		}
	}, []);

	return (
		<>
			<div className='add-device-wrapper'>
				{auth?.userDetails.user_type !== 'db_admin' ? (
					<div className='radio-input-container'>
						<label className='input-label' >
							<FontAwesomeIcon icon={faUser} style={{ color: '#F1EBFF' }} />
							User Type:
						</label>
						<label for="doctor" className='radio-button'>
							<input
								type='radio'
								name='user_type'
								checked={auth.userDetails.user_type === 'doctor'}
								onClick={handleRadioChange}
								id='doctor'
							/>
							<Badge
								badgeTitle="Doctor"
								additionalClass='badge-light-purple'
							/>
						</label>
						<label for="device_expert" className='radio-button'>
							<input
								type='radio'
								name='user_type'
								checked={auth.userDetails.user_type === 'device_expert'}
								onClick={handleRadioChange}
								id='device_expert'
							/>
							<Badge
								badgeTitle="Device Expert"
								additionalClass='badge-pink'
							/>
						</label>
					</div>
				) : (
					<div className='radio-input-container'>
						<label className='input-label' >
							<FontAwesomeIcon icon={faUser} style={{ color: '#F1EBFF' }} />
							User Type:
						</label>
						<label for="db_admin" className='radio-button'>
							<input
								type='radio'
								name='user_type'
								checked
								onClick={null}
								id='db_admin'
							/>
							<Badge
								badgeTitle="Device Expert"
								additionalClass='badge-purple'
							/>
						</label>
					</div>
				)}
				<div className='profile-settings-content'>
					<div className='input-container grid1'>
						<label for="user-key" className='input-label'>
							<FontAwesomeIcon icon={faKey} style={{ color: '#F1EBFF' }} />
							User Key:
						</label>
						<input
							type='text'
							value={auth.user_key[0]}
							onChange={null}
							disabled
							placeholder="User Key"
							id='user-key'
						/>
					</div>
					<div className='input-container grid2'>
						<label for="full-name" className='input-label'>
							<FontAwesomeIcon icon={faIdCard} style={{ color: '#F1EBFF' }} />
							Full Name:
						</label>
						<input
							type='text'
							value={auth.userDetails.user_name}
							onChange={handleInputChange}
							placeholder="Full Name"
							id='user_name'
						/>
					</div>
					<div className='input-container grid3'>
						<label for="mail-address" className='input-label'>
							<FontAwesomeIcon icon={faEnvelope} style={{ color: '#F1EBFF' }} />
							Mail Address:
						</label>
						<input
							type='text'
							value={auth.userDetails.user_mail}
							onChange={handleInputChange}
							placeholder="User Mail"
							id='user_mail'
						/>
					</div>
					<div className='input-container grid4'>
						<label for="user-phone" className='input-label'>
							<FontAwesomeIcon icon={faPhone} style={{ color: '#F1EBFF' }} />
							Phone Number:
						</label>
						<input
							type='text'
							value={auth.userDetails.user_phone}
							onChange={handleInputChange}
							placeholder="User Phone"
							id='user_phone'
						/>
					</div>
					<div className='input-container grid5'>
						<label for="user-location" className='input-label'>
							<FontAwesomeIcon icon={faLocationDot} style={{ color: '#F1EBFF' }} />
							Location:
						</label>
						<input
							type='text'
							value={auth.userDetails.user_location}
							onChange={handleInputChange}
							placeholder="User Location"
							id='user_location'
						/>
					</div>
				</div>
				<div className='add-device-button-container'>
					<button className='secondary-button' onClick={() => props.setIsOpen(false)}>Cancel</button>
					<button className='primary-button' onClick={() => handleClick(auth.userDetails)}>{isFirstState ? 'Create Profile' : 'Update Profile'}</button>
				</div>
			</div>
		</>
	);
}
