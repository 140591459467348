import CryptoJS from 'crypto-js/';

export const encryptAES = (text, key) => (
    CryptoJS.AES.encrypt(text, key).toString()
);

export const decryptAES = (encryptedBase64, key) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key);
    if (decrypted) {
        try {
            const str = decrypted.toString(CryptoJS.enc.Utf8);
            if (str.length > 0) {
                return str;
            } else {
                return 'Key Pairs did not match!';
            }
        } catch (e) {
            console.error(e);
            return 'error code: 2';
        }
    }
    return 'error code: 3';
};
