import React from 'react';
import ProfileButton from './ProfileButton';
import HeaderDashboardButtons from './HeaderDashboardButtons';
import { useDispatch } from 'react-redux';
import { CHAIN_IS_ALLOWED } from '../../actions/types/uiActionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';

export default function ContentHeader(props) {
    const { ui, authUser } = props;
    const dispatch = useDispatch();
    const userType = authUser.userDetails.user_type || 'Guest';
    //todo: profile section can be moved to another component
    return (
        <div className='content-header-container'>
            <p className='content-header'>
                {props.contentHeader}
            </p>
            {!ui.isChainAllowed &&
                (
                    <div className='chainAlert'>
                        CipherMed currently only operates on Sepolia Testnet Network.
                        <button
                            className='action-button'
                            type='button'
                            onClick={() => dispatch({ type: CHAIN_IS_ALLOWED })}
                        >
                            <FontAwesomeIcon icon={faTrash} style={{ color: '#F1EFFF' }} title='Remove Device' />
                        </button>
                    </div>
                )
            }
            <div className='header-button-wrapper'>
                {props.page === 'dashboard' &&
                    <HeaderDashboardButtons
                        userType={userType}
                        ui={ui}
                        {...props}
                    />
                }
                <ProfileButton
                    userType={userType}
                    authUser={props.authUser}
                />
            </div>
        </div>
    )
}
