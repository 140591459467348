import { SET_DUMMY_DATA, CATEGORIZE_DUMMY_DATA } from '../actions/types/reportActionTypes';

const INITIAL_REPORT_STATE = {
  rawData: [],
  alarms: [],
  pauses: [],
  paramaters: []
}

export const reportReducer = (state = INITIAL_REPORT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DUMMY_DATA:
      return { ...state, rawData: [...payload] };
    case CATEGORIZE_DUMMY_DATA:
      return { ...state, alarms: action.finalAlarmObject, pauses: action.PauseObjects, paramaters: action.finalParameterObject };
    default:
      return state;
  }
};
