import React from 'react'

export default function Header() {
  return (
    <div className='header-container'>
      <p className='landing-header'>Revolutionizing Patient Follow-Up with Advanced Technology</p>
      <p className='landing-subheader'>
        The safest way to track your patients health!
      </p>
    </div>
  )
}
