import React from 'react';

export default function Pauses({ pauses }) {
    return (
        <>
            <h1>Pauses</h1>
            <div className='content-table'>
                {Object.values(pauses).map(pause => {
                    return (
                        <div className='device-item'>
                            <div className='col1'>
                                <p className='prop-title'>
                                    Date Time:
                                </p>
                                <p className='prop-content'>
                                    {pause?.DateTime}
                                </p>
                            </div>
                            <div className='col1'>
                                <p className='prop-title'>
                                    Device Status:
                                </p>
                                <p className='prop-content'>
                                    {pause?.Pause ? 'Paused' : 'Working'}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}