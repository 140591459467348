import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark
 } from '@fortawesome/free-solid-svg-icons';

export default function Modal(props) {
  return (
    <>
        <div className="modal-bg" onClick={() => props.setIsOpen(false)} />
            <div className="centered">
            <div className={`modal ${props.additionalClass}`}>
                <button type="button" onClick={() => props.setIsOpen(false)} className='close-modal'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                <div className="modal-header">
                    <h5 className="modal-heading">{props.modalHeader}</h5>
                </div>
                <div className="modal-content">
                    {props.modalContent}
                </div>
                <div className="modal-actions" />
            </div>
        </div>
    </>
  )
}