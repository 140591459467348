/* eslint-disable no-useless-escape */
import React from 'react';
import '../../App.css';

export default function PreviewMail(props) {
	const { ui } = props;

	const data = `<!DOCTYPE html> 
		< html > 
	<head><base target=\"_blank\"> 
	<meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"> 
	<meta name=\"viewport\" content=\"width=device-width; initial-scale=1.0; maximum-scale=1.0;\"> 
	 
	<link href=\"https://fonts.googleapis.com/css?family=Sarala:400,700|Hind+Vadodara:400,300,500,600,700|Open+Sans:400,800italic,800,700italic,700,600italic,600,400italic,300italic,300|Raleway:400,200,100,300,500,600,700,800,900|Montserrat:400,700,800,900|Rubik:400,300,300italic,400italic,900italic,900,700italic,700,500italic,500|Lato:400,100italic,100,300,300italic,400italic,700,700italic,900,900italic|Questrial|Muli:400,300italic,300,400italic|Work+Sans:400,100,200,300,500,600,700,800,900|Nunito:400,700,300|Anton|Playfair+Display:400,400italic,700,700italic,900,900italic|Varela+Round|Oswald:200,300,400,500,600,700\" rel=\"stylesheet\" type=\"text/css\"> 
	 
	<style type=\"text/css\"> 
	 
	td[class].anton {font-family: 'Anton', Arial, sans-serif !important} 
	td[class].raleway {font-family: 'Raleway', Arial, sans-serif !important} 
	td[class].vadodara {font-family: 'Vadodara', Arial, sans-serif !important} 
	td[class].opensans {font-family: 'Open Sans', Arial, sans-serif !important} 
	td[class].rubik {font-family: 'Rubik', Arial, sans-serif !important} 
	td[class].montserrat {font-family: 'Montserrat', Arial, sans-serif !important} 
	td[class].hindvadodara {font-family: 'Hind Vadodara', Arial, sans-serif !important} 
	td[class].lato {font-family: 'Lato', Arial, sans-serif !important} 
	td[class].questrial {font-family: 'Questrial', Arial, sans-serif !important} 
	td[class].muli {font-family: 'Muli', Arial, sans-serif !important} 
	td[class].worksans {font-family: 'Work Sans', Arial, sans-serif !important} 
	td[class].nunito {font-family: 'Nunito', Arial, sans-serif !important} 
	td[class].playfair {font-family: 'Playfair Display', Arial, sans-serif !important} 
	td[class].varela {font-family: 'Varela Round', Arial, sans-serif !important} 
	td[class].oswald {font-family: 'Oswald', Arial, sans-serif !important} 
	 
	div, p, a, li, td { -webkit-text-size-adjust:none; } 
	 
	*{-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;} 
	td{word-break: break-word;} 
	a{word-break: break-word; text-decoration: none; color: inherit;} 
	 
	body .ReadMsgBody 
	{width: 100%; background-color: #ffffff;} 
	body .ExternalClass 
	{width: 100%; background-color: #ffffff;} 
	body{width: 100%; height: 100%; background-color: #ffffff; margin:0; padding:0; -webkit-font-smoothing: antialiased;} 
	html{ background-color:#ffffff; width: 100%;} 
	 
	body p {padding: 0!important; margin-top: 0!important; margin-right: 0!important; margin-bottom: 0!important; margin-left: 0!important; } 
	body img {user-drag: none; -moz-user-select: none; -webkit-user-drag: none;} 
	body .hover:hover {opacity:0.85;filter:alpha(opacity=85);} 
	body #opacity {opacity:0.90;filter:alpha(opacity=90);} 
	 
	body .img120 img {width: 120px; height: auto;} 
	body .img20 img {width: 20px; height: auto;} 
	body .image490 img {width: 490px; height: auto;} 
	body .image190 img {width: 190px; height: auto;} 
	body .icon18 img {width: 18px; height: auto;} 
	body .img61 img {width: 61px; height: auto;} 
	body .img10 img {width: 10px; height: auto;} 
	body .img24 img {width: 24px; height: auto;} 
	body .image430 img {width: 430px; height: auto;} 
	body .img98 img {width: 98px; height: auto;} 
	body .img13 img {width: 13px; height: auto;} 
	 
	</style> 
	 
	<style type=\"text/css\">@media only screen and (max-width: 640px){ 
			body body{ width: auto!important; } 
			body table[class= full2] { width: 100 % !important; clear: both; }
	body.premadeFullScale img{ width: 100 % !important; }

}</style >

	<style type=\"text/css\">@media only screen and (max-width: 479px){  
			body body{ width: auto!important; } 
			body table[class= full] { width: 100 % !important; clear: both; } 
			body table[class= full2] { width: 100 % !important; clear: both; } 
			body table[class= fullCenter] { width: 100 % !important; text - align: center!important; clear: both; } 
			body td[class].fullCenter { width: 100 % !important; text - align: center!important; clear: both; } 
			body table[class= fullLeft] { width: 100 % !important; text - align: left!important; clear: both; } 
			body table[class= fullRight] { width: 100 % !important; text - align: right!important; clear: both; }
body.fullCenter { width: 100 % !important; text - align: center!important; clear: both; }
body.fullLeft { width: 100 % !important; text - align: left!important; clear: both; }
body.fullRight { width: 100 % !important; text - align: right!important; clear: both; }
body * [class= erase] { display: none; }
body * [class= buttonScale] { float: none!important; text - align: center!important; display: inline - block!important; clear: both; }
body * [class= h15] { width: 100 % !important; height: 15px!important; }
body * [class= h20] { width: 100 % !important; height: 20px!important; }
body * [class= h30] { width: 100 % !important; height: 30px!important; }
body * [class= h50] { width: 100 % !important; height: 50px!important; } 
			body td[class].pad20 { padding - left: 20px!important; padding - right: 20px!important; text - align: center!important; clear: both; }
body.erase { display: none!important; }
body.pad20 { padding - left: 20px!important; padding - right: 20px!important; text - align: center!important; clear: both; }
body.pad10 { padding - left: 10px!important; padding - right: 10px!important; text - align: center!important; clear: both; }
body.imgFull img { width: 100 % !important; }
body.newRow { display: block!important; }
body.f40 { font - size: 40px!important; line - height: 48px!important; }
body.f50 { font - size: 50px!important; }
body.f60 { font - size: 60px!important; }
body.f60_2 { font - size: 60px!important; line - height: 70px!important; }
body.premadeFullScale img{ width: 100 % !important; } 
	 
	}</style > 
	 
	</head > 
	<body style='margin: 0; padding: 0;'> 
	 
	<table width=\"100%\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" bgcolor=\"#e4eaeb\" style=\"background-color: #e4eaeb;\"> 
		<tbody><tr> 
			<td align=\"center\"> 
				 
				<table class=\"full\" align=\"center\" border=\"0\" width=\"100%\" cellpadding=\"0\" cellspacing=\"0\"> 
					<tbody><tr> 
						<td align=\"center\" class=\"pad10\"> 
							 
							<table width=\"450\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" style=\"border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; width: 450px;\" class=\"fullCenter\"> 
								<tbody><tr> 
									<td width=\"100%\" height=\"70\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
								</tr> 
							</tbody></table> 
							 
							<table width=\"450\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" style=\"width: 450px; background-color: #002c42;\" bgcolor=\"#002c42\"> 
								<tbody><tr> 
									<td valign=\"middle\" align=\"left\" width=\"100%\" style=\"line-height: 1px;\"> 
									</td> 
								</tr> 
								<tr> 
									<td width=\"100%\" align=\"center\" class=\"pad20\"> 
										 
										<table width=\"400\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" style=\"width: 400px;\"> 
											<tbody><tr> 
												<td width=\"100%\" height=\"0\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr> 
											<tr> 
												<td valign=\"middle\" align=\"center\" width=\"100%\" class=\"fullCenter\" style=\"line-height: 1px;\"> 
												</td> 
											</tr> 
											<tr> 
												<td width=\"100%\" height=\"20\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr> 
											<tr> 
												<td width=\"100%\" style=\"color: #ffffff; font-family: Helvetica, Arial, sans-serif, 'Raleway'; font-weight: 700; vertical-align: top; font-size: 30px; text-align: center; line-height: 34px;\" class=\"fullCenter raleway\"> 
												   ${ui?.previewContent.title}
												</td> 
											</tr >
	<tr>
		<td width=\"100%\" height=\"50\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr > 
										</tbody ></table > 
										 
									</td > 
								</tr >
	<tr>
		<td width=\"100%\" align=\"center\" bgcolor=\"#00afb8\" style=\"background-color: #00afb8;\">

		<table width=\"450\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" style=\"width: 450px;\">
		<tbody><tr>
			<td valign=\"middle\" align=\"center\" width=\"100%\" class=\"fullCenter imgFull\" style=\"line-height: 1px;\">
			<a href=\"https://ciphermed.app/\" style=\"text-decoration: none;\">
			<img src=\"images/shadow_2.png\" width=\"450\" alt=\"\" border=\"0\">
		</a>
		</td>
	</tr> 
										</tbody ></table > 
										 
									</td > 
								</tr > 
								<tr> 
									<td width=\"100%\" align=\"center\" class=\"pad20\" bgcolor=\"#00afb8\" style=\"background-position: center center; background-repeat: no-repeat; background-color: #00afb8;\"> 
										 
										<table width=\"400\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" style=\"width: 400px;\"> 
											<tbody><tr> 
												<td width=\"100%\" height=\"10\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr> 
											<tr> 
												<td width=\"100%\" height=\"30\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr >
	<tr>
		<td width=\"100%\" style=\"color: #ffffff; font-family: Helvetica, Arial, sans-serif, 'Raleway'; font-weight: 400; vertical-align: top; font-size: 15px; text-align: center; line-height: 20px;\" class=\"fullCenter raleway\">
		<p>${ui?.previewContent.content}</p>
	</td> 
											</tr >
	<tr>
		<td width=\"100%\" height=\"45\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr > 
										</tbody ></table > 
										 
									</td > 
								</tr >
	<tr>
		<td width=\"100%\" align=\"center\" bgcolor=\"#008bab\" style=\"background-color: #008bab;\">

		<table width=\"450\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" style=\"width: 450px;\">
		<tbody><tr>
			<td valign=\"middle\" align=\"center\" width=\"100%\" class=\"fullCenter imgFull\" style=\"line-height: 1px;\">
			<a href=\"https://ciphermed.app/\" style=\"text-decoration: none;\">
			<img src=\"images/shadow_2-2.png\" width=\"450\" alt=\"\" border=\"0\">
		</a>
		</td>
	</tr> 
										</tbody ></table > 
										 
									</td > 
								</tr > 
								<tr> 
									<td width=\"100%\" align=\"center\" class=\"pad20\" bgcolor=\"#008bab\" style=\"background-color: #008bab;\"> 
										 
										<table width=\"400\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" class=\"full\" style=\"width: 400px;\"> 
											<tbody><tr> 
												<td width=\"100%\" height=\"10\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr> 
											<tr> 
												<td width=\"100%\" style=\"color: #ffffff; font-family: Helvetica, Arial, sans-serif, 'Raleway'; font-weight: 400; vertical-align: top; font-size: 15px; text-align: center; line-height: 20px;\" class=\"fullCenter raleway\"> 
													<br class=\"erase\"> 
												<span style=\"font-weight: 700;\">This alert created by ${ui?.previewContent.patient}'s own device.</span> 
											   <br><br>
												<span style=\"font-weight: 700;\">Doctor: ${ui?.previewContent.doctor.split('_')[0]}</span> 
												<br>
											   <span style=\"font-weight: 700;\">Device Expert: ${ui?.previewContent.device_expert.split('_')[0]}</span> 
												</td> 
											</tr >
	<tr>
		<td width=\"100%\" height=\"15\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr > 
											<tr> 
											</tr> 
											<tr> 
												<td width=\"100%\" height=\"20\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr >
	<tr>
		<td width=\"100%\" style=\"color: #ffffff; font-family: Helvetica, Arial, sans-serif, 'Raleway'; font-weight: 400; vertical-align: top; font-size: 12px; text-align: center; line-height: 18px;\" class=\"fullCenter raleway\">
		<a href=\"https://ciphermed.app/main/reports/${ui?.previewContent.patient}\" style=\"text-decoration: none; color: #ffffff;\">Go to Patient's Dashboard</a> 
												</td > 
											</tr >
	<tr>
		<td width=\"100%\" height=\"30\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
											</tr > 
										</tbody ></table > 
										 
									</td > 
								</tr > 
								 
							</tbody ></table >

	<table width=\"400\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" align=\"center\" style=\"border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; width: 400px;\" class=\"fullCenter\"> 
	 <tr>
			<td width=\"100%\" height=\"30\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
								</tr >
	<tr>
		<td width=\"100%\" style=\"color: #002d42; font-family: Helvetica, Arial, sans-serif, 'Raleway'; font-weight: 400; vertical-align: top; font-size: 12px; text-align: center; line-height: 18px;\" class=\"fullCenter raleway\">
		©2023 <a href=\"https://ciphermed.app\">Project Ciphermed</a>. All Rights Reserved. 
									</td > 
								</tr >
	<tr>
		<td width=\"100%\" height=\"10\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
								</tr > 
								<tr> 
								</tr> 
								<tr> 
									<td width=\"100%\" height=\"60\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
								</tr >
	<tr>
		<td width=\"100%\" height=\"1\" style=\"font-size: 1px; line-height: 1px;\"> </td> 
								</tr > 
							</tbody ></table > 
							 
						</td > 
					</tr > 
				</tbody ></table >

	<table align=\"center\" class=\"full\" width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\" style=\"table-layout:fixed;\"> 
		 <tr>
			<td class=\"erase androidfix\" style=\"line-height:1px; font-size:1px;\" width=\"400\"><img src=\"images/transparant.png\" height=\"1\" style=\"max-height:1px; min-height:1px; display:block; width:400px; min-width:400px;\" border=\"0\"></td> 
					</tr > 
				</tbody ></table > 
				 
			</td > 
		</tr > 
	</table > 
	 
	</body > 
	</html > `;

	return (
		<div className='add-device-wrapper'>
			<div dangerouslySetInnerHTML={{ __html: data }} />
			<div className='add-device-button-container'>
				<button className='primary-button' onClick={() => props.setIsOpen(false)}>Close</button>
			</div>
		</div >
	);
}
