import React, { useState, useEffect } from 'react';
import { ethers } from "ethers";
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlus
} from '@fortawesome/free-solid-svg-icons';
import DeviceService from '../services/deviceService';
import AddDevice from '../components/Device/AddDevice';
import PairDevice from '../components/Device/PairDevice';
import '../App.css';
import { FETCH_DEVICES_SUCCESS, SET_PAIRED_DEVICES } from '../actions/types/deviceActionTypes';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Common/SidePanel';
import DeviceItem from '../components/Device/DeviceItem';
import ContentHeader from '../components/Common/ContentHeader';
import Modal from '../components/Common/Modal';
import { sortWithEpochDate, isHexValid } from '../utils/helpers';
import { convertEpochtoDatePair } from '../utils/methods';

import { showPairCode, deleteDevice, updateAccessStatus, renewPairCode, getAllPairedDevices } from '../utils/helpers';
import ScrollUpButton from '../components/Common/ScrollUpButton';
import { decryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';

export default function Devices(props) {
	const { devices, auth, ui, alerts } = props;
	const { device_filters } = ui;
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenPair, setIsOpenPair] = useState(false);
	const [updateRequired, setUpdateRequired] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isEthereumConnected = window.ethereum.isConnected();

	if (!auth.isAuthorized || !isEthereumConnected) {
		navigate(`/`, { replace: false });
	}
	const userType = auth?.userDetails.user_type || 'Guest';

	useEffect(() => {
		if (auth.isAuthorized && auth?.userDetails.user_type === 'db_admin' && (Object.values(devices.baseDevices).length === 0 || updateRequired)) {
			DeviceService.fetchAllDevices(auth).then((devices) => {
				const { data } = devices;
				const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
				dispatch({ type: FETCH_DEVICES_SUCCESS, payload: decryptedData });
				setUpdateRequired(false);
			}).catch((err) => {
				throw new Error(err);
			});
		}
	}, [auth, updateRequired]);

	useEffect(() => {
		if (auth.isAuthorized && auth?.userDetails.user_type !== 'db_admin') {
			getAllPairedDevices().then(pairedDevices => dispatch({ type: SET_PAIRED_DEVICES, payload: pairedDevices }));
		}
	}, [auth]);

	return (
		<div className="App">
			{isOpen &&
				<Modal
					setIsOpen={setIsOpen}
					actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
					modalHeader="Add New Device"
					modalContent={<AddDevice setIsOpen={setIsOpen} auth={auth} />}
					additionalClass="add-device-modal"
				/>}
			{isOpenPair &&
				<Modal
					setIsOpen={setIsOpenPair}
					actionLabel="Pair"
					modalHeader="Pair New Device"
					modalContent={<PairDevice setIsOpen={setIsOpenPair} ui={ui} auth={auth} />}
				/>}
			<div className="devices">
				<SidePanel
					authUser={auth}
					alerts={alerts}
				/>
				<div className='main-content-wrapper'>
					<ContentHeader
						contentHeader="ALL DEVICES"
						authUser={auth}
						ui={ui}
						onClickPrimary={setIsOpen}
						onClickSecondary={setIsOpenPair}
						page="dashboard"
						setSearchQuery={setSearchQuery}
						searchQuery={searchQuery}
					/>
					{userType === 'db_admin' ? (
						<ul className='content-table'>
							{sortWithEpochDate(Object.values(devices.baseDevices), device_filters.sort.prop)
								.filter(base => (
									base.device_id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
									|| base.doctor_id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
									|| base.patient_id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
									|| base.device_expert_id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
									|| base.patient_contact.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1)
								)
								.filter(base => (device_filters.isAccessible.prop !== null ? (base.access_status === (device_filters.isAccessible.prop ? 'ON' : 'OFF')) : true))
								.filter(base => (device_filters.isPairCodeExpired.prop !== null ? (device_filters.isPairCodeExpired.prop ? (convertEpochtoDatePair(base.expire_date).readableTime > 0) && (base.doctor_id === null || base.device_expert_id === null) : false) : true))
								.filter(base => (device_filters.pairedWithDoctor.prop !== null ? (device_filters.pairedWithDoctor.prop ? (base.doctor_id !== null) : true) : true))
								.filter(base => (device_filters.pairedWithExpert.prop !== null ? (device_filters.pairedWithExpert.prop ? (base.device_expert_id !== null) : true) : true))
								.map(device => (
									<DeviceItem
										device={device}
										updateAccessStatus={updateAccessStatus}
										showPairCode={showPairCode}
										onClickDelete={deleteDevice}
										onClickEdit={renewPairCode}
										dispatch={dispatch}
										auth={auth}
										setUpdateRequired={setUpdateRequired}
									/>
								))}
						</ul>
					) : (
						<div className='content-table'>
							<h1>Your Paired Devices</h1>
							{devices.pairedDevices && Object.values(devices.pairedDevices).filter(aa => aa[3]).map(pairedDevice =>
								(isHexValid(pairedDevice.deviceId._hex) || isHexValid(pairedDevice.patientId._hex)) && (
									<p> {(`${ethers.utils.parseBytes32String(pairedDevice.deviceId._hex)}`)} belongs to patient #
										{(`${ethers.utils.parseBytes32String(pairedDevice.patientId._hex)}`)}
										{isHexValid(pairedDevice.ownerType._hex) && (` - Ownertype: ${ethers.utils.parseBytes32String(pairedDevice.ownerType._hex)}`)}
										{isHexValid(pairedDevice.pairDate._hex) && (`- Pair Date: ${ethers.utils.parseBytes32String(pairedDevice.pairDate._hex)}`)}
										{isHexValid(pairedDevice.patientContactInfo._hex) && (` - Patient Contact Info: ${ethers.utils.parseBytes32String(pairedDevice.patientContactInfo._hex)}`)}</p>
								))}
							<p>Asaddas belongs to patient #dasdsadsa - Ownertype: guest- Pair Date: 17.05.2023 09:14:42 - Patient Contact Info: 56548546847</p>
							<p>asd belongs to patient #asd - Ownertype: guest- Pair Date: 17.05.2023 09:16:32 - Patient Contact Info: 324</p>
						</div>
					)}
				</div>
				<ScrollUpButton />
			</div>
		</div>
	);
}
