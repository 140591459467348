import React from 'react';
import moment from 'moment';
import '../App.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Common/SidePanel';
import Table from '../components/Device/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBell, faChartSimple, faInfo, faUser, faUserDoctor, faPaperPlane, faTriangleExclamation, faEye, faEnvelope, faFileLines, faTrash } from '@fortawesome/free-solid-svg-icons';
import ContentHeader from '../components/Common/ContentHeader';
import Badge from '../components/Common/Badge';
import { deleteAlert, resendSingleMail, resendAllMails } from '../utils/helpers';
import { HANDLE_MAIL_PREVIEW } from '../actions/types/uiActionTypes';

export default function Alerts(props) {
	const { auth, alerts, ui } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isEthereumConnected = window.ethereum.isConnected();

	if (!auth.isAuthorized || !isEthereumConnected) {
		navigate(`/`, { replace: false });
	}

	const TABLEHEADERS = [
		{ icon: <FontAwesomeIcon icon={faBed} />, title: 'Patient' },
		{ icon: <FontAwesomeIcon icon={faUserDoctor} />, title: 'Doctor' },
		{ icon: <FontAwesomeIcon icon={faUser} />, title: 'Device Expert' },
		{ icon: <FontAwesomeIcon icon={faBell} />, title: 'Alert Title' },
		{ icon: <FontAwesomeIcon icon={faInfo} />, title: 'Alert Detail' },
		{ icon: <FontAwesomeIcon icon={faChartSimple} />, title: 'Alert Status' },
		{ icon: <FontAwesomeIcon icon={faTriangleExclamation} />, title: 'Created At' },
		{ icon: <FontAwesomeIcon icon={faPaperPlane} />, title: 'Sent At' },
		{ icon: <FontAwesomeIcon icon={faEnvelope} />, title: 'Mail Actions' },
	]

	return (
		<div className="App">
			<header className="devices">
				<SidePanel
					authUser={auth}
					alerts={alerts}
				/>
				<div className='main-content-wrapper'>
					<ContentHeader
						contentHeader="CREATED MAIL ALERTS"
						authUser={auth}
						ui={ui}
					/>
					<Table
						tableHeaders={TABLEHEADERS}
					>
						{auth.userDetails.user_type === 'db_admin' &&
							<button type='button' onClick={() => resendAllMails(alert, auth, dispatch)}>SEND ALL PENDING/FAILED MAILS</button>
						}
						{
							Object.values(alerts).length > 0 ? (
								Object.values(alerts).map(alert => {
									const doctorText = alert.doctor.split('_');
									const expertText = alert.device_expert.split('_');
									return (
										<tr className='table-body-row'>
											<td data-label="Patient">{alert.patient}</td>
											<td data-label="Doctor">{doctorText[0]}{doctorText.length > 1 && (<p>receiver</p>)}</td>
											<td data-label="Device Expert">{expertText[0]}{expertText.length > 1 && (<p>receiver</p>)}</td>
											<td data-label="Alert Title">{alert.title}</td>
											<td data-label="Alert Detail">{alert.content}</td>
											<td title={alert.mail_to} data-label="Alert Status">
												<Badge
													badgeTitle={alert.status}
													additionalClass={(alert.status === 'SENT') ? 'badge-green' : (alert.status === 'PENDING') ? 'badge-yellow' : 'badge-red'}
												/>
											</td>
											<td data-label="Created At">{moment(alert.create_date).format('DD.MM.YYYY hh:mm:ss')}</td>
											<td data-label="Sent At">{alert.sent_date !== null ? moment(alert.sent_date).utc().format('DD.MM.YYYY hh:mm:ss') : 'Waiting to Send'}</td>
											<td data-label="Actions" className='table-action-buttons'>
												{auth.userDetails.user_type === 'db_admin' && (
													<button
														className='action-button'
														type='button'
														title='Resend Email'
														onClick={() => resendSingleMail(alert, auth, dispatch)}
													>
														<FontAwesomeIcon icon={faPaperPlane} style={{ color: '#F1EFFF', width: '16px', height: '16px' }} title='Resend Email' />
													</button>
												)}
												<button
													className='action-button'
													type='button'
													title='Preview Email'
													onClick={() => dispatch({ type: HANDLE_MAIL_PREVIEW, payload: { modal: 'previewMail', content: alert } })}
												>
													<FontAwesomeIcon icon={faEye} style={{ color: '#F1EFFF', width: '16px', height: '16px' }} title='Preview Email' />
												</button>
												{auth.userDetails.user_type !== 'db_admin' && (
													<button
														className='action-button'
														type='button'
														title='Go to Reports'
														onClick={() => navigate(`/main/reports/${alert.patient}`, { replace: true })}
													>
														<FontAwesomeIcon icon={faFileLines} style={{ color: '#F1EFFF', width: '16px', height: '16px' }} title='Go to Reports' />
													</button>
												)}
												{auth.userDetails.user_type === 'db_admin' && (
													<button
														className='action-button'
														type='button'
														title='Remove Alert'
														onClick={() => deleteAlert(alert, auth, dispatch)}
													>
														<FontAwesomeIcon icon={faTrash} style={{ color: '#F1EFFF', width: '16px', height: '16px' }} title='Remove Alert' />
													</button>
												)}
											</td>
										</tr>
									)
								})) : (<p>Data not found!</p>)
						}
					</Table>
				</div>
			</header>
		</div>
	)
}
