import React from 'react';

export default function Badge(props) {
  return (
    <div
        className={`badge ${props.additionalClass}`}
    >
        {props.badgeTitle}
    </div>
  )
}