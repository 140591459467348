export const allowedChains = ['0xaa36a7']; // Sepolia mainnet code.

export const allowedUsers = [
    { user_key: '0x03ed6155561af36be41c1d1eba92443a085afe99', user_type: 'db_admin' }, // Merter Eker #DB Admin
    { user_key: '0x0B2c586fd188bf91B62b29ac611709F5cf2FAc67', user_type: 'db_admin' }, // Berrin #DB Admin
    { user_key: '0x422b85691dba7b62daaafcbb96f059f88e18b10f', user_type: 'db_admin' }, // Helin #DB Admin
    // { user_key: '0xffc893ade105afc3a93028146aeee3aa119a5899', user_type: 'doctor' }, // Merter Eker #Doctor
    { user_key: '0x3d460c6c61ea70eb5e79b8187d84df23df231cc3', user_type: 'device_expert' }, // Merter Eker #Device Expert
    { user_key: '0x576afb3e135d66969d04ec76f06fe20709f343ed', user_type: 'db_admin' }, // Merter Eker #Ciphermed Dev
    { user_key: '0x0b2c586fd188bf91b62b29ac611709f5cf2fac67', user_type: 'db_admin' }, // Berrin Yava #DB Admin
    { user_key: '0x4f83b41e515a23430d359cd3adbe2d316adcad12', user_type: 'db_admin' }, // İrfan Laçin #Regular
    { user_key: '0xF3e59C343ea73B6EAaf89734Ab4a42934dCB6801', user_type: 'doctor' }, // Merter Eker #Doctor
    { user_key: '0xd7B1E239a5f589F62468d40F8E96Fb87b6560765', user_type: 'doctor' }, // Berrin Yava #Doctor
    { user_key: '0x60C4e662919c1C2c7010ef6CA44B69BDE40dCC70', user_type: 'device_expert' }, // Berrin Yava #Device Expert
    { user_key: '0x770dD1D6DAa2606a5225684866dA6b005D39Ecff', user_type: 'doctor' }, // Berrin Yava #Device Expert
];

export const disabledSidebars = ['settings']; // Disabled for now.

export const encryptionKey = 'U2FSdJDje89GxrmeXxcY7jSLf';
