import React from 'react';

export default function Table(props) {
  return (
    <table className='content-table'>
        <thead>
            <tr className='table-header-row'>
                {props.tableHeaders.map(header => {
                    return <th key={header}>{header.icon} {header.title}</th>
                })}
            </tr>
        </thead>
        <tbody className='table-body'>
            {props.children}
        </tbody>
    </table>
  )
}
