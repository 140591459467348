// components/LineChart.js
import React from "react";
import { Line } from "react-chartjs-2";

export const LineChart = ({ chartData }) => {
    return (
        <div className="chart-container">
            <Line
                data={chartData}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: "Device Paramaters"
                        },
                        legend: {
                            display: false
                        }
                    }
                }}
            />
        </div>
    );
}