import React, { useEffect, useState } from 'react';
import { ethers } from "ethers";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../App.css';
import SidePanel from '../components/Common/SidePanel';
import Alarms from '../components/Reports/Alarms';
import Paramaters from '../components/Reports/Paramaters';
import { SET_PAIRED_DEVICES } from '../actions/types/deviceActionTypes';
import Pauses from '../components/Reports/Pauses';
import DeviceService from '../services/deviceService';
import { processDummyData } from '../utils/helpers';
import { SET_DUMMY_DATA } from '../actions/types/reportActionTypes';
import { getAllPairedDevices, isHexValid } from '../utils/helpers';
import { REPORT_TYPES } from '../utils/constants';
import ContentHeader from '../components/Common/ContentHeader';

export default function Reports(props) {
	const { auth, report, devices, ui, alerts } = props;
	const { alarms, paramaters, pauses } = report;
	const dispatch = useDispatch();
	const [displayMode, setDisplayMode] = useState('default');
	const { id } = useParams();
	const [selectedPatient, setSelectedPatient] = useState(id);
	useEffect(() => {
		if (auth.isAuthorized && selectedPatient !== 'default') {
			DeviceService.getDummyData(auth).then(dummy => {
				processDummyData(dummy.data, dispatch);
				dispatch({ type: SET_DUMMY_DATA, payload: dummy.data });
			}).catch((err) => {
				throw new Error(err);
			});
		}
	}, [auth, selectedPatient]);

	useEffect(() => {
		if (devices.pairedDevices && auth.isAuthorized && auth?.userDetails.user_type !== 'db_admin' && Object.values(devices.pairedDevices).length === 0) {
			getAllPairedDevices().then(pairedDevices => dispatch({ type: SET_PAIRED_DEVICES, payload: pairedDevices }));
		}
	}, [auth]);

	return (
		<div className="App">
			<header className="devices">
				<SidePanel
					authUser={auth}
					alerts={alerts}
				/>
				<div className='main-content-wrapper'>
					<ContentHeader
						contentHeader="REPORTS"
						authUser={auth}
						ui={ui}
						page="reports"
					/>
					{devices.pairedDevices && (
						<select style={{ marginTop: '100px' }} value={selectedPatient} onChange={(e) => setSelectedPatient(e.target.value)}>
							<option value="default">Select a Patient</option>
							{Object.values(devices.pairedDevices).filter(aa => aa[3]).map(pairedDevice => isHexValid(pairedDevice.patientId._hex) && (
								<option value={ethers.utils.parseBytes32String(pairedDevice.patientId._hex)}>{ethers.utils.parseBytes32String(pairedDevice.patientId._hex)}</option>
							))}
						</select>
					)}
					{selectedPatient !== 'default' && (<>
						<select value={displayMode} onChange={(e) => setDisplayMode(e.target.value)}>
							<option value="default">Select a Report Type</option>
							{REPORT_TYPES.map(paramater => (
								<option value={paramater}>{paramater}</option>
							))}
						</select>
						{
							{
								'paramaters': <Paramaters paramaters={paramaters} selectedPatient={selectedPatient} />,
								'alarms': <Alarms alarms={alarms} />,
								'pauses': <Pauses pauses={pauses} />
							}[displayMode]
						}
					</>)}
				</div>
			</header>
		</div>
	)
}
