import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowUp
} from '@fortawesome/free-solid-svg-icons';

export default function ScrollUpButton() {
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handleScrolButtonVisiblity = () => {
            window.pageYOffset > 50 ? setShowScrollButton(true) : setShowScrollButton(false);
        };

        window.addEventListener('scroll', handleScrolButtonVisiblity);

        return () => {
            window.removeEventListener('scroll', handleScrolButtonVisiblity);
        }
    }, []);

    const handleScrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (
        <>
            {showScrollButton && (
                <button
                    className="scroll-top-button" type='button'
                    onClick={handleScrollUp}
                >
                    <FontAwesomeIcon icon={faArrowUp} style={{ color: '#F1EFFF' }} />
                </button>
            )}
        </>
    )
}