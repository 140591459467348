import React from 'react';
import { Link } from 'react-scroll';

export default function Navbar() {
  return (
    <header className='navbar-wrapper'>
      <h1 className='Logo'>
        CipherMed
      </h1>
      <nav className='navbar-container'>
        <ul>
          <Link duration={500} activeClass='active-nav-item' className='nav-item' smooth spy to="home">Home</Link>

          <Link duration={500} activeClass='active-nav-item' className='nav-item' smooth spy to="about">About</Link>
        </ul>
      </nav>
    </header>
  )
}
