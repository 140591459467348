import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilter
} from '@fortawesome/free-solid-svg-icons';
import { UPDATE_BOOL_FILTER, CLEAR_FILTERS } from '../../actions/types/uiActionTypes';

export default function FilterDropdown({ device_filters }) {
    const dispatch = useDispatch();
    const handleOnChange = (e) => {
        dispatch({ type: UPDATE_BOOL_FILTER, payload: { target: e.target.id, value: e.target.id === 'sort' ? e.target.value : e.target.value !== "true" } })
    }

    const handleOnClear = () => {
        dispatch({ type: CLEAR_FILTERS })
    }
    return (
        <>
            <h1>
                <FontAwesomeIcon icon={faFilter} style={{ color: '##F1EBFF' }} />
                Filter Results
            </h1>
            {Object.entries(device_filters).some(filter => (filter[1].prop !== null && filter[1].prop !== 'createAsc')) && (<button onClick={handleOnClear}>Clear Filters</button>)}
            {Object.entries(device_filters).map(([key, value]) => (
                <div className='filter-toggle-container'>
                    <p className="prop-title">{value.text}:</p>
                    {key === 'sort' ? (
                        <select value={value.prop} id={key} onChange={handleOnChange}>
                            <option value="createAsc">Date Created (Asc)</option>
                            <option value="createDesc">Date Created (Desc)</option>
                            <option value="pairAsc">Date Paired (Asc)</option>
                            <option value="pairDesc">Date Paired (Desc)</option>
                        </select>
                    ) : (
                        <label className="switch">
                            <input
                                id={key}
                                value={value.prop}
                                checked={value.prop}
                                type="checkbox"
                                onChange={handleOnChange}
                            />
                            <span className="slider round"></span>
                        </label>
                    )}
                </div>
            ))}
        </>
    )
}