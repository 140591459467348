
export const convertEpochtoDate = (
	(timestamp) => {
		const time = Date.now() - Math.abs(timestamp);
		let readableTime;
		let units;
		if (time > (1000 * 60 * 60 * 24 * 365)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
			units = 'years';
		} else if (time > (1000 * 60 * 60 * 24 * 30)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
			units = 'months';
		} else if (time > (1000 * 60 * 60 * 24 * 7)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
			units = 'weeks';
		} else if (time > (1000 * 60 * 60 * 24)) {
			readableTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
			units = 'days';
		} else if (time > (1000 * 60 * 60)) {
			readableTime = parseInt(time / (1000 * 60 * 60), 10);
			units = 'hours';
		} else if (time > (1000 * 60)) {
			readableTime = parseInt(time / (1000 * 60), 10);
			units = 'minutes';
		} else {
			readableTime = parseInt(time / (1000), 10);
			units = 'seconds';
		}
		return `${readableTime} ${units}`;
	});

export const convertEpochtoDatePair = (
	(timestamp) => {
		const time = Date.now() - Math.abs(timestamp);
		const readableTime = parseInt(time / (1000), 10);
		return { readableTime };
	});
