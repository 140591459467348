export const normalizeUserTypes = {
    'db_admin': "Database Admin",
    'doctor': "Doctor",
    'device_expert': "Device Expert",
    'guest': "Guest",
    null: "Guest"
};

export const defaultFilters = {
    sort: 'pairDate',
    isAccessible: true,
    isPairCodeActive: true,
    isAlertExists: true,
    pairedStatus: 'all|doctor|device_expert',
    belongsToDoctor: null,
    belongsToDeviceExpert: null,
};

export const NORMALIZE_ALARM_CODES = {
    0: "UNKNOWN",
    1: "LOW PRESSURE",
    2: "HIGH PRESSURE",
    4: "LOW VOLUME",
    8: "HIGH VOLUME",
    9: "LOW RATE",
    11: "HIGH RATE",
    16: "LOW RATE",
    32: "HIGH RATE"
}

export const NORMALIZE_PATTERN_CODES = {
    0: "Square",
    1: "Ramp",
    2: "Sine",
}

export const NORMALIZE_PATIENT_CODES = {
    1: "Newborn",
    2: "Baby",
    3: "Adult",
}

export const PARAMATER_GROUPS = [
    'vtidal',
    'pinsp',
    'sense',
]

export const REPORT_TYPES = [
    'paramaters',
    'alarms',
    'pauses',
]