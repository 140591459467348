import { FETCH_ALERTS_SUCCESS, DELETE_ALERT_SUCCESS } from '../actions/types/alertActionTypes';

const INITIAL_ALERT_STATE = [];

export const alertReducer = (state = INITIAL_ALERT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALERTS_SUCCESS:
      return { ...state, ...payload };
    case DELETE_ALERT_SUCCESS:
      return { ...Object.values(state).filter((alert) => alert.patient !== payload) };
    default:
      return state;
  }
};
