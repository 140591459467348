import React, { useState, useEffect } from 'react';
import '../../App.css';
import { useDispatch } from 'react-redux';
import DeviceService from '../../services/deviceService';
import { ethers } from "ethers";
import { DeviceContractAddress } from "../../config";
import DeviceAbi from '../../dapp/build/contracts/DeviceContract.json';
import { DEVICE_PAIR_SUCCESS, DEVICE_PAIR_ERROR, ETHEREUM_CONFIG_ERROR } from '../../actions/types/deviceActionTypes';
import { TRANSACTION_CHECK_NOTIFICATION, RESET_TRANSACTION_CHECK_NOTIFICATION } from '../../actions/types/uiActionTypes';
import moment from 'moment/moment';
import { decryptAES } from '../../utils/cipherMethods';
import { encryptionKey } from '../../utils/rules';

export default function PairDevice(props) {
	const { auth, ui } = props;
	const [pairCode, setPairCode] = useState('');
	const [pairType, setPairType] = useState(auth?.userDetails.user_type);
	const [ongoingTransaction, setOngoingTransaction] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({ type: RESET_TRANSACTION_CHECK_NOTIFICATION });
	}, []);

	const handlePairDevice = async deviceConfig => {
		const { device_id, patient_id, patient_contact } = deviceConfig;
		let device = {
			deviceId: device_id,
			patientId: patient_id,
			patientContact: patient_contact,
			ownerType: auth?.userDetails.user_type|| 'guest'
		}

		try {
			const { ethereum } = window;
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum)
				const signer = provider.getSigner();
				const DeviceContract = new ethers.Contract(
					DeviceContractAddress,
					DeviceAbi.abi,
					signer
				)
				const dateTime = moment().format('DD.MM.YYYY hh:mm:ss');
				DeviceContract.addDevice(
					ethers.utils.formatBytes32String(device.deviceId),
					ethers.utils.formatBytes32String(device.patientId),
					ethers.utils.formatBytes32String(dateTime),
					ethers.utils.formatBytes32String(device.patientContact),
					ethers.utils.formatBytes32String(device.ownerType),
				).then(res => {
					const { hash } = res;
					dispatch({ type: TRANSACTION_CHECK_NOTIFICATION, payload: hash });
					DeviceService.updatePairStatus(device.deviceId, pairType, auth).then(success => {
						dispatch({ type: DEVICE_PAIR_SUCCESS, payload: success.data });
						setOngoingTransaction(false);
					}).catch((err) => {
						setOngoingTransaction(false);
						dispatch({ type: DEVICE_PAIR_ERROR, payload: err });
					})
				}).catch(err => {
					setOngoingTransaction(false);
					dispatch({ type: DEVICE_PAIR_ERROR, payload: err })
				})
			}
		} catch (error) {
			dispatch({ type: ETHEREUM_CONFIG_ERROR, payload: error })
		}
	}

	const initDevicePair = (pairCode, pairType) => {
		setOngoingTransaction(true);
		DeviceService.getDeviceConfig(pairCode, pairType, auth).then(deviceConfig => {
			const { data } = deviceConfig;
			const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
			handlePairDevice(decryptedData);
		}).catch((err) => {
			setOngoingTransaction(false);
			dispatch({ type: DEVICE_PAIR_ERROR, payload: err.response.data })
		})
	};

	const onChangeValue = e => (
		setPairType(e.target.value)
	)

	const handleCloseModal = () => {
		props.setIsOpen(false);
	}

	return (
		<>
			<div className='add-device-wrapper'>
				<div className='input-container'>
					<label for="device-id">Pair Code</label>
					<input
						type='text'
						value={pairCode}
						onChange={(e) => setPairCode(e.target.value)}
					/>
					<div onChange={onChangeValue}>
						<input type="radio" id='doctor' checked={pairType === "doctor"} value="doctor" name="gender" />
						<label for="doctor">Doctor</label>
						<input type="radio" id='device_expert' checked={pairType === "device_expert"} value="device_expert" name="gender" />
						<label for="device_expert">Device Expert</label>
					</div>
					{ui.displayTestnetInfo.prop && (
						<p>{ui.displayTestnetInfo.message} <a target='_blank' href={ui.displayTestnetInfo.url} rel="noreferrer">Etherscan</a></p>
					)}
				</div>
				<div className='add-device-button-container'>
					<button className='secondary-button' onClick={handleCloseModal}>Cancel</button>
					<button className='primary-button' disabled={pairType === ''} onClick={() => initDevicePair(pairCode.trim(), pairType)}>{ongoingTransaction ? 'Processing...' : 'Pair Device'}</button>
				</div>
			</div>
		</>
	);
}
