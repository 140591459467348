import { combineReducers } from 'redux';
import { deviceReducer } from './deviceReducer';
import { uiReducer } from './uiReducer';
import { authReducer } from './authReducer';
import { alertReducer } from './alertReducer';
import { reportReducer } from './reportReducer';

export default combineReducers({
	deviceReducer,
	uiReducer,
	authReducer,
	alertReducer,
	reportReducer
});
