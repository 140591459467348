import React from 'react'

export default function PrimaryButton(props) {
  return (
    <button
        onClick={() => props.onClick(true)}
        className="primary-button"
    >   
        {props.buttonIcon && props.buttonIcon}
        {props.label}
    </button>
  )
}