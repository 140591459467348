import Main from './pages/main';
import Devices from './pages/devices';
import Alerts from './pages/alerts';
import Reports from './pages/reports';

export const routes = [
  {
    path: '/',
    exact: true,
    component: Main,
  },
  {
    path: '/*',
    exact: true,
    component: Main,
  },
  {
    path: '/main/dashboard',
    exact: true,
    component: Devices,
  },
  {
    path: '/main/mail-alerts',
    exact: true,
    component: Alerts,
  },
  {
    path: '/main/reports/:id',
    exact: true,
    component: Reports,
  }
];
