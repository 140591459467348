import React, { useEffect } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import Authenticate from '../components/Authenticate'
import Header from '../components/Landing/header';
import UserPanel from '../components/UserPanel';
import Navbar from '../components/Landing/navbar';
import LandingBackground from '../assets/background/bg.mp4';

export default function Main(props) {
  const { auth } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthorized) {
      navigate(`/main/dashboard`, { replace: true });
    }
  }, [auth, navigate]);

  return (
    <>
      <div className='landing'>
        <Navbar />
        <section className="section landing-hero" id="home">
          <video className='background-video' autoPlay loop muted>
            <source src={LandingBackground} type='video/mp4' />
          </video>
          <div className='landing-content'>
            <Header />
            {auth.isAuthorized ? <UserPanel auth={auth} /> : <Authenticate />}
          </div>
        </section>
        <section className='section landing-about' id="about">
          about
        </section>
      </div>
    </>
  );
}
