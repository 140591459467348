import React, { useState, useEffect } from 'react';
import { getParameterDetails } from '../../utils/helpers';
import { LineChart } from '../Charts/LineChart';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { PARAMATER_GROUPS } from '../../utils/constants';

export default function Paramaters({ paramaters, selectedPatient }) {
    Chart.register(CategoryScale);
    const [singleParamaters, setSingleParamaters] = useState([]);
    const [chartData, setChartData] = useState({});
    const [paramaterDetails, setParamaterDetails] = useState([]);
    const [paramaterMode, setParamaterMode] = useState('default');

    useEffect(() => {
        setParamaterDetails(getParameterDetails(paramaters));
        setSingleParamaters(paramaters.map(parameter => parameter.at(-1)));
        switch (paramaterMode) {
            case 'vtidal':
                setChartData({
                    labels: singleParamaters.map((data) => data?.DateTime),
                    datasets: [
                        {
                            label: "maxVTidal",
                            data: singleParamaters.map((data) => data?.patameters?.maxVTidal),
                            borderColor: "white",
                            borderWidth: 1
                        },
                        {
                            label: "minVTidal",
                            data: singleParamaters.map((data) => data?.patameters?.minVTidal),
                            borderColor: "red",
                            borderWidth: 1
                        },
                        {
                            label: "vTidal",
                            data: singleParamaters.map((data) => data?.patameters?.vTidal),
                            borderColor: "yellow",
                            borderWidth: 1
                        }
                    ]
                });
                break;
            case 'pinsp':
                setChartData({
                    labels: singleParamaters.map((data) => data?.DateTime),
                    datasets: [
                        {
                            label: "maxPinsp",
                            data: singleParamaters.map((data) => data?.patameters?.maxPinsp),
                            borderColor: "white",
                            borderWidth: 1
                        },
                        {
                            label: "minPinsp",
                            data: singleParamaters.map((data) => data?.patameters?.minPinsp),
                            borderColor: "red",
                            borderWidth: 1
                        },
                        {
                            label: "pinsp",
                            data: singleParamaters.map((data) => data?.patameters?.pinsp),
                            borderColor: "yellow",
                            borderWidth: 1
                        }
                    ]
                });
                break;
            case 'sense':
                setChartData({
                    labels: singleParamaters.map((data) => data?.DateTime),
                    datasets: [
                        {
                            label: "iSense",
                            data: singleParamaters.map((data) => data?.patameters?.iSense),
                            borderColor: "white",
                            borderWidth: 1
                        },
                        {
                            label: "eSense",
                            data: singleParamaters.map((data) => data?.patameters?.eSense),
                            borderColor: "red",
                            borderWidth: 1
                        }
                    ]
                });
                break;
            default:
                break;
        }
    }, [paramaterMode]);

    return (
        <>
            <h1>Paramaters</h1>
            <select value={paramaterMode} onChange={(e) => setParamaterMode(e.target.value)}>
                <option value="default">Select a Group</option>
                {PARAMATER_GROUPS.map(paramater => (
                    <option value={paramater}>{paramater}</option>
                ))}
            </select>
            <p>Patient Name: {selectedPatient}</p>
            <p>Last Data Fetched: {paramaterDetails?.lastSeen}</p>
            {/* <p>Data Count: {paramaterDetails?.instanceSize}</p> */}
            <p>Patient Type: {paramaterDetails?.patient}</p>
            <p>Pattern Type: {paramaterDetails?.pattern}</p>
            <div className='content-table'>
                {Object.keys(chartData).length > 0 && (<LineChart chartData={chartData} />)}
            </div>
        </>
    )
}