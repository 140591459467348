import React, { useState } from 'react'
import { useOnClickOutsideRef } from '../../utils/helpers';

export default function SecondaryButton(props) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const modalRef = useOnClickOutsideRef(() => setDropdownIsOpen(false))

  const onButtonClick = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  }
  return (
    <div ref={modalRef} className='secondary-button-container'>
      <button
        onClick={props.dropdown ? onButtonClick : props.onClick}
        className="secondary-button"
        title={props.title}
      >
        {props.buttonIcon && props.buttonIcon}
        <p>{props.label}</p>
      </button>
      {props.dropdown && (
        <div className={`dropdown-container ${dropdownIsOpen ? 'dropdownIsOpen' : ''}`}>
          {props.children}
        </div>
      )}
    </div>
  )
}