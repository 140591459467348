import React from 'react';
import { useDispatch } from 'react-redux';
import { LOGOUT_OPERATION } from '../actions/types/authActionTypes';
import Button from './Landing/button';
import '../App.css';

export default function UserPanel({ auth }) {
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch({ type: LOGOUT_OPERATION });
	}
	return (
		<>
			<p>Hello <b>{auth.user_key[0]}</b></p>
			<Button
				onClick={handleLogout}
				label="Logout"
			/>
		</>
	);
}
