export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_ERROR = 'FETCH_DEVICES_ERROR';

export const RENEW_PAIR_CODE_SUCCESS = 'RENEW_PAIR_CODE_SUCCESS';
export const RENEW_PAIR_CODE_ERROR = 'RENEW_PAIR_CODE_ERROR';

export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR';

export const UPDATE_ACCESS_STATUS_SUCCESS = 'UPDATE_ACCESS_STATUS_SUCCESS';
export const UPDATE_ACCESS_STATUS_ERROR = 'UPDATE_ACCESS_STATUS_ERROR';

export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_ERROR = 'ADD_DEVICE_ERROR';

export const SHOW_PAIR_CODE = 'SHOW_PAIR_CODE';

export const DEVICE_PAIR_SUCCESS = 'DEVICE_PAIR_SUCCESS';
export const DEVICE_PAIR_ERROR = 'DEVICE_PAIR_ERROR';
export const ETHEREUM_CONFIG_ERROR = 'ETHEREUM_CONFIG_ERROR';

export const SET_PAIRED_DEVICES = 'SET_PAIRED_DEVICES';