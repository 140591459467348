/* eslint-disable class-methods-use-this */
import http from '../http-common';
import { encryptAES } from '../utils/cipherMethods';
import { encryptionKey } from '../utils/rules';

class UserService {
	createUser(user, auth) {
		return http.post(`api/user/create`, {
			user_key: encryptAES(auth.user_key[0], encryptionKey),
			user_name: encryptAES(user.user_name, encryptionKey),
			user_type: encryptAES(user.user_type, encryptionKey),
			user_mail: encryptAES(user.user_mail, encryptionKey),
			user_phone: encryptAES(user.user_phone, encryptionKey),
			user_location: encryptAES(user.user_location, encryptionKey)
		});
	}

	getUserDetails(auth) {
		return http.post(`api/user/get`, {
			user_key: encryptAES(auth.user_key[0], encryptionKey)
		});
	}

	updateUser(user, auth) {
		return http.post(`api/user/update`, {
			user_key: encryptAES(auth.user_key[0], encryptionKey),
			user_name: encryptAES(user.user_name, encryptionKey),
			user_type: encryptAES(user.user_type, encryptionKey),
			user_mail: encryptAES(user.user_mail, encryptionKey),
			user_phone: encryptAES(user.user_phone, encryptionKey),
			user_location: encryptAES(user.user_location, encryptionKey)
		});
	}
}
export default new UserService();
