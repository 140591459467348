import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
	faTrash,
	faLaptopMedical,
	faUserDoctor,
	faBed,
	faCalendarDays,
	faUser,
	faCheck,
	faAddressBook,
	faArrowRotateRight,
	faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { convertEpochtoDate, convertEpochtoDatePair } from '../../utils/methods';
import Badge from '../Common/Badge';

export default function DeviceItem(props) {
	const { device, auth, updateAccessStatus, showPairCode, onClickEdit, onClickDelete, dispatch, setUpdateRequired } = props;
	const [remainingTime, setRemainingTime] = useState(Math.abs(convertEpochtoDatePair(device.expire_date).readableTime));
	const pairCodeStatus = (device.device_expert_id && device.doctor_id) ? 'All Paired' : (device.device_expert_id && !device.doctor_id) ? 'Expired for Doctor' : (!device.device_expert_id && device.doctor_id) ? 'Expired for Device Expert' : (!device.device_expert_id && !device.doctor_id) ? 'Pair Code Expired' : 'No Data';
	useEffect(() => {
		let interval = null;
		interval = setInterval(() => {
			setRemainingTime(seconds => seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [remainingTime]);
	return (
		<li className='device-item'>
			<input type="checkbox" className='accordion-checkbox' defaultChecked={true} />
			<div className='main-content'>
				<div className='col-container'>
					<FontAwesomeIcon icon={faLaptopMedical} style={{ color: '#F1EFFF' }} />
					<p className="prop-title">Device:</p>
					<p className='prop-content'>{device.device_id}</p>
				</div>
				<div className='col-container' id='accessStatus'>
					<p className="prop-title">Access Status:</p>
					<label className="switch">
						<input
							type="checkbox"
							onChange={() => updateAccessStatus(device, auth, device.access_status, dispatch, setUpdateRequired)}
							checked={device.access_status === 'ON'}
						/>
						<span className="slider round"></span>
					</label>
				</div>
				<div className='col-container'>
					<button
						className='action-button'
						type='button'
						onClick={() => onClickEdit(device, auth, dispatch, setUpdateRequired)}
					>
						<FontAwesomeIcon icon={faArrowRotateRight} style={{ color: '#F1EFFF', width: '16px' , height: '16px' }} title='Renew Pair Code' />
					</button>
					<button
						className='action-button'
						type='button'
						onClick={() => device.pair_code.length > 0 && showPairCode(device.pair_code, dispatch)}
					>
						<FontAwesomeIcon icon={faCopy} style={{ color: '#F1EFFF', width: '16px' , height: '16px' }} title='Copy Pair Code' />
					</button>
					<button
						className='action-button'
						type='button'
						onClick={() => onClickDelete(device, auth, dispatch, setUpdateRequired)}
					>
						<FontAwesomeIcon icon={faTrash} style={{ color: '#F1EFFF', width: '16px' , height: '16px'}} title='Remove Device' />
					</button>
				</div>
				<i></i>
			</div >
			<div className='detail-content'>
				<div className='info-column'>
					<div className='col-container'>
						<FontAwesomeIcon icon={faUserDoctor} style={{ color: '#F1EFFF' }} />
						<p className="prop-title">Paired Doctor:</p>
						<p
							className='prop-content'
							id='copiable'
							title={device.doctor_id || 'No Doctor'}
							onClick={() => { navigator.clipboard.writeText(device.doctor_id || 'No Doctor') }}
						>
							{device.doctor_id || 'No Doctor'}
						</p>
					</div>
					<div className='col-container'>
						<FontAwesomeIcon icon={faUser} style={{ color: '#F1EFFF' }} />
						<p className="prop-title">Device Expert</p>
						<p className='prop-content'>{device.device_expert_id || 'No Expert'}</p>
					</div>
					<div className='col-container'>
						<FontAwesomeIcon icon={faBed} style={{ color: '#F1EFFF' }} />
						<p className="prop-title">Paired Patient:</p>
						<p className='prop-content'>{device.patient_id}</p>
					</div>
					<div className='col-container'>
						<FontAwesomeIcon icon={faAddressBook} style={{ color: '#F1EFFF' }} />
						<p className="prop-title">Patient Contact Info:</p>
						<p className='prop-content'>{device.patient_contact}</p>
					</div>
				</div>
				<div className='info-column'>
					<div className='col-container'>
						<FontAwesomeIcon icon={faCalendarDays} style={{ color: '#F1EFFF' }} />
						<p className="prop-title">Date Created:</p>
						<p className='prop-content' title={moment.unix(device.creation_date / 1000).format("MM.DD.YYYY hh:mm")}>Created {convertEpochtoDate(device.creation_date)} ago</p>
					</div>
					<div className='col-container'>
						<FontAwesomeIcon icon={faCheck} style={{ color: '#F1EFFF' }} />
						<p className="prop-title">Pair Code Status:</p>
						{convertEpochtoDatePair(device.expire_date).readableTime > 0 ? (
							<Badge 
								badgeTitle={pairCodeStatus}
								additionalClass={pairCodeStatus === 'All Paired' ? 'badge-green' : 'badge-red'}
							/>
						) : (<p className='prop-content'>
							{remainingTime} seconds left
						</p>)}
					</div>
					<div className='col-container' id='accessStatusMobile'>
						<p className="prop-title">Access Status:</p>
						<label className="switch">
							<input
								type="checkbox"
								onChange={() => updateAccessStatus(device, auth, device.access_status, dispatch, setUpdateRequired)}
								checked={device.access_status === 'ON'}
							/>
							<span className="slider round"></span>
						</label>
					</div>
				</div>
			</div>
		</li >
	)
}
