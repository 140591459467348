import React from 'react';
import { getAlarmDetails } from '../../utils/helpers';

export default function Alarms({ alarms }) {
    return (
        <>
            <h1>Alarms</h1>
            <div className='content-table'>
                {Object.values(alarms).map(alarm => {
                    const { isSingleInstance, firstSeen, lastSeen, alarmType, duration } = getAlarmDetails(alarm);
                    return (
                        <div className='device-item'>
                            <div className='col1'>
                                <p className='prop-title'>
                                    Alarm Type:
                                </p>
                                <p className='prop-content'>
                                    {alarmType}
                                </p>
                            </div>
                            <div className='col2'>
                                {isSingleInstance ? (
                                    <div>
                                        <p className='prop-title'>
                                            Seen:
                                        </p>
                                        <p className='prop-content'>
                                            {firstSeen}
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <p className='prop-title'>
                                                Started:
                                            </p>
                                            <p className='prop-content'>
                                                {firstSeen}
                                            </p>
                                        </div>
                                        <div>
                                            <p className='prop-title'>
                                                Ended:
                                            </p>
                                            <p className='prop-content'>
                                                {lastSeen}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='col3'>
                                <div className='col2'>
                                    <div>
                                        <p className='prop-title'>
                                            Duration:
                                        </p>
                                        <p className='prop-content'>
                                            {duration} seconds
                                        </p>
                                    </div>
                                </div>
                                <div className='col2'>
                                    <div>
                                        <p className='prop-title'>
                                            Alarm Count:
                                        </p>
                                        <p className='prop-content'>
                                            {alarm.length}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}