import React from 'react'

export default function Button(props) {
  return (
    <button
        onClick={props.onClick}
        className="landing-button"
    >   
        {props.imgSrc &&
            <img src={props.imgSrc} className="landing-button-icon" alt={props.alt} />
        }
        <span>{props.label}</span>
        {props.arrowSrc &&
            <img src={props.arrowSrc} className="button-arrow-icon" alt='arrow icon'/>
        }
    </button>
  )
}
