import {
	FETCH_DEVICES_ERROR, RENEW_PAIR_CODE_SUCCESS, RENEW_PAIR_CODE_ERROR,
	DELETE_DEVICE_SUCCESS, DELETE_DEVICE_ERROR, UPDATE_ACCESS_STATUS_SUCCESS,
	UPDATE_ACCESS_STATUS_ERROR, ADD_DEVICE_SUCCESS, ADD_DEVICE_ERROR, SHOW_PAIR_CODE,
	DEVICE_PAIR_SUCCESS, DEVICE_PAIR_ERROR, ETHEREUM_CONFIG_ERROR
} from '../actions/types/deviceActionTypes';
import { AUTH_SUCCESS, AUTH_ERROR, AUTH_METAMASK_REQUIRED, LOGOUT_OPERATION } from '../actions/types/authActionTypes';
import { DELETE_ALERT_ERROR, DELETE_ALERT_SUCCESS, RESEND_MAIL_SUCCESS, RESEND_MAIL_ERROR, RESEND_ALL_MAILS_ERROR, RESEND_ALL_MAILS_SUCCESS } from '../actions/types/alertActionTypes';
import {
	CHAIN_NOT_ALLOWED, UPDATE_ACTIVE_MODAL, HANDLE_MAIL_PREVIEW, UPDATE_BOOL_FILTER, CLEAR_FILTERS, UPDATE_PROFILE_INFO_SUCCESS,
	UPDATE_PROFILE_INFO_ERROR, TRANSACTION_CHECK_NOTIFICATION, RESET_TRANSACTION_CHECK_NOTIFICATION, CHAIN_IS_ALLOWED
} from '../actions/types/uiActionTypes';
import { displayInfoToast, displayErrorToast } from '../utils/helpers';

const INITIAL_UI_STATE = {
	errorStack: [],
	notificationStack: [],
	device_filters: {
		sort: { text: 'Sort Results', prop: 'createAsc' },
		isAccessible: { text: 'Accessible Devices', prop: null },
		isPairCodeExpired: { text: 'Pair Code Expired', prop: null },
		pairedWithDoctor: { text: 'Paired with Doctor', prop: null },
		pairedWithExpert: { text: 'Paired with Expert', prop: null },
	},
	activeModal: null,
	previewContent: {},
	isChainAllowed: true,
	displayTestnetInfo: { prop: false, message: '', url: '' }
}

export const uiReducer = (state = INITIAL_UI_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_DEVICES_ERROR:
			displayErrorToast(payload.message);
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case RENEW_PAIR_CODE_SUCCESS:
			displayInfoToast(payload.message);
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case RENEW_PAIR_CODE_ERROR:
			displayErrorToast(payload.message);
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case DELETE_DEVICE_SUCCESS:
			displayInfoToast(payload.message);
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case DELETE_DEVICE_ERROR:
			displayErrorToast(payload.message);
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case UPDATE_ACCESS_STATUS_SUCCESS:
			displayInfoToast(payload.message);
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case UPDATE_ACCESS_STATUS_ERROR:
			displayErrorToast(payload.message);
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case AUTH_SUCCESS:
			// displayInfoToast('LOGIN SUCCESS');
			return { ...state, notificationStack: [...state.errorStack, { message: 'LOGIN SUCCESS', loc: type }] };
		case AUTH_ERROR:
			if (payload.message.indexOf('eth_requestAccounts') > -1) {
				displayErrorToast(`Metamask screen is already open, please check the taskbar.`);
			} else {
				displayErrorToast(payload.message);
			}
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case AUTH_METAMASK_REQUIRED:
			displayErrorToast(payload);
			return { ...state, errorStack: [...state.errorStack, { message: payload, loc: type }] };
		case LOGOUT_OPERATION:
			// displayInfoToast('LOGOUT SUCCESS');
			return { ...state, notificationStack: [...state.errorStack, { message: 'LOGOUT SUCCESS', loc: type }] };
		case CHAIN_NOT_ALLOWED:
			return { ...state, isChainAllowed: false };
		case CHAIN_IS_ALLOWED:
			return { ...state, isChainAllowed: true };
		case ADD_DEVICE_SUCCESS:
			displayInfoToast(payload.message);
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case ADD_DEVICE_ERROR:
			displayErrorToast(payload.message);
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case SHOW_PAIR_CODE:
			displayInfoToast(payload);
			return { ...state, notificationStack: [...state.notificationStack, { message: payload, loc: type }] };
		case DEVICE_PAIR_SUCCESS:
			displayInfoToast(payload.message);
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case DEVICE_PAIR_ERROR:
			if (payload.message.indexOf('ACTION_REJECTED') > -1) {
				displayErrorToast(`You've rejected the pair request`);
			} else {
				displayErrorToast(payload.message);
			}
			return { ...state, errorStack: [...state.errorStack, { message: payload.message, loc: type }] };
		case ETHEREUM_CONFIG_ERROR:
			displayErrorToast(payload);
			return { ...state, errorStack: [...state.errorStack, { message: payload, loc: type }] };
		case UPDATE_ACTIVE_MODAL:
			return { ...state, activeModal: payload };
		case HANDLE_MAIL_PREVIEW:
			return { ...state, activeModal: payload.modal, previewContent: payload.content };
		case UPDATE_BOOL_FILTER:
			return { ...state, device_filters: { ...state.device_filters, [payload.target]: { ...state.device_filters[payload.target], prop: payload.value } } };
		case CLEAR_FILTERS:
			return {
				...state, device_filters: {
					...state.device_filters,
					sort: { ...state.device_filters.sort, prop: 'createAsc' },
					isAccessible: { ...state.device_filters.isAccessible, prop: null },
					isPairCodeExpired: { ...state.device_filters.isPairCodeExpired, prop: null },
					pairedWithDoctor: { ...state.device_filters.pairedWithDoctor, prop: null },
					pairedWithExpert: { ...state.device_filters.pairedWithExpert, prop: null }
				}
			};
		case UPDATE_PROFILE_INFO_SUCCESS:
			displayInfoToast('Profile Info Successfully Updated!');
			return { ...state };
		case UPDATE_PROFILE_INFO_ERROR:
			displayErrorToast('Profile Info cannot be Updated!');
			return { ...state };
		case DELETE_ALERT_SUCCESS:
			displayInfoToast('Alert deleted successfully!');
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case DELETE_ALERT_ERROR:
			displayErrorToast('Alert cannot deleted!');
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case RESEND_MAIL_SUCCESS:
			displayInfoToast('Mail resent successfully!');
			return { ...state, notificationStack: [...state.notificationStack, { message: payload.message, loc: type }] };
		case RESEND_MAIL_ERROR:
			displayErrorToast('Alert cannot be sent again!');
			return { ...state, errorStack: [...state.notificationStack, { loc: type }] };
		case RESEND_ALL_MAILS_SUCCESS:
			displayInfoToast('All Pending/Failed mails resent successfully!');
			return { ...state, errorStack: [...state.notificationStack, { loc: type }] };
		case RESEND_ALL_MAILS_ERROR:
			displayErrorToast('Alerts cannot be sent again!');
			return { ...state, errorStack: [...state.notificationStack, { loc: type }] };
		case TRANSACTION_CHECK_NOTIFICATION:
			return { ...state, displayTestnetInfo: { prop: true, message: `We've received your request! You can check the process on:`, url: `https://sepolia.etherscan.io/tx/${payload}` } };
		case RESET_TRANSACTION_CHECK_NOTIFICATION:
			return { ...state, displayTestnetInfo: { prop: false, message: '', url: '' } };
		default:
			return state;
	}
};
