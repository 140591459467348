import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_SUCCESS, AUTH_ERROR, AUTH_METAMASK_REQUIRED } from '../actions/types/authActionTypes';
import '../App.css';
import Button from './Landing/button';
import MetamaskLogo from '../assets/logo/metamaskLogo.png';
import ArrowIcon from '../assets/icon/ButtonArrow.svg';

export default function Authenticate() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleMetamaskLogin = () => {
		if (window.ethereum) {
			window.ethereum.request({ method: 'eth_requestAccounts' })
				.then(result => {
					dispatch({ type: AUTH_SUCCESS, payload: result });
					navigate(`/main/dashboard`, { replace: false });
				}).catch((err) => {
					dispatch({ type: AUTH_ERROR, payload: err });
				});
		} else {
			dispatch({ type: AUTH_METAMASK_REQUIRED, payload: 'Please install MetaMask!' });
		}
	}
	return (
		<Button
			onClick={handleMetamaskLogin}
			label="Connect to MetaMask"
			imgSrc={MetamaskLogo}
			arrowSrc={ArrowIcon}
			alt="MetaMask Logo"
		/>
	);
}
