import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLaptopMedical,
	faBed
} from '@fortawesome/free-solid-svg-icons';
import '../../App.css';
import DeviceService from '../../services/deviceService';
import { useDispatch } from 'react-redux';
import { ADD_DEVICE_SUCCESS, ADD_DEVICE_ERROR, FETCH_DEVICES_SUCCESS, FETCH_DEVICES_ERROR } from '../../actions/types/deviceActionTypes';
import { decryptAES } from '../../utils/cipherMethods';
import { encryptionKey } from '../../utils/rules';

export default function AddDevice(props) {
	const { auth } = props;

	const [deviceID, setDeviceID] = useState('');
	const [patientID, setPatientID] = useState('');
	const [patientContactInfo, setPatientContactInfo] = useState('');
	const dispatch = useDispatch();

	const handleAddDevice = (device) => (
		DeviceService.addSingleDevice(device, auth).then((singleDevice) => {
			dispatch({ type: ADD_DEVICE_SUCCESS, payload: singleDevice.data });
			// TODO :: update devices on redux instead of calling again.
			DeviceService.fetchAllDevices(auth).then((devices) => {
				const { data } = devices;
				const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
				dispatch({ type: FETCH_DEVICES_SUCCESS, payload: decryptedData });
				props.setIsOpen(false);
			}).catch((err) => {
				dispatch({ type: FETCH_DEVICES_ERROR, payload: err });
			});
		}).catch((err) => {
			dispatch({ type: ADD_DEVICE_ERROR, payload: err });
		})
	)

	return (
		<>
			<div className='add-device-wrapper'>
				<div className='input-container'>
					<label for="device-id" className='input-label'>
						<FontAwesomeIcon icon={faLaptopMedical} />
						Device Id:
					</label>
					<input
						type='text'
						value={deviceID}
						onChange={(e) => setDeviceID(e.target.value)}
						placeholder="Device Id"
						id='device-id'
					/>
				</div>
				<div className='input-container'>
					<label for="patient-id" className='input-label'>
						<FontAwesomeIcon icon={faBed} />
						Patient Id:
					</label>
					<input
						type='text'
						value={patientID}
						onChange={(e) => setPatientID(e.target.value)}
						placeholder="Patient Id"
						id='patient-id'
					/>
				</div>
				<div className='input-container'>
					<label for="patient-contact-info" className='input-label'>
						<FontAwesomeIcon icon={faBed} />
						Patient Contact Info:
					</label>
					<input
						type='text'
						value={patientContactInfo}
						onChange={(e) => setPatientContactInfo(e.target.value)}
						placeholder="Patient Contact Info"
						id='patient-contact-info'
					/>
				</div>
				<div className='add-device-button-container'>
					<button className='secondary-button' onClick={() => props.setIsOpen(false)}>Cancel</button>
					<button className='primary-button' onClick={() => handleAddDevice({ deviceID, patientID, patientContactInfo })}>+ Add Device</button>
				</div>
			</div>
		</>
	);
}
