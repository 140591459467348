import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CHAIN_NOT_ALLOWED, CHAIN_IS_ALLOWED } from './actions/types/uiActionTypes';
import { LOGOUT_OPERATION } from './actions/types/authActionTypes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { routes } from './routes';
import NotificationCenter from './components/NotificationCenter'
import { allowedChains } from './utils/rules';
import { AUTH_SUCCESS } from './actions/types/authActionTypes';
import Modal from './components/Common/Modal';
import ProfileSettings from './components/Device/ProfileSettings';
import PreviewMail from './components/Device/PreviewMail';
import { UPDATE_ACTIVE_MODAL } from './actions/types/uiActionTypes';
import { decryptAES } from './utils/cipherMethods';
import { encryptionKey } from './utils/rules';
import AlertService from './services/alertService';
import { FETCH_ALERTS_SUCCESS } from './actions/types/alertActionTypes';

function App(props) {
  const dispatch = useDispatch();
  const { auth, ui, alerts } = props;
  const accountWasChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      dispatch({ type: LOGOUT_OPERATION });
    } else {
      if (accounts !== auth.user_key) {
        dispatch({ type: AUTH_SUCCESS, payload: accounts });
      }
    }
  }, [dispatch, auth]);

  const chainWasChanged = useCallback((chain) => {
    if (!allowedChains.includes(chain)) {
      dispatch({ type: CHAIN_NOT_ALLOWED });
    } else {
      dispatch({ type: CHAIN_IS_ALLOWED });
    }
  }, [dispatch]);

  useEffect(() => {
    if (auth.isAuthorized) {
      if (auth?.userDetails.user_type === 'db_admin') {
        AlertService.fetchAllAlerts(auth).then(alerts => {
          const { data } = alerts;
          const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
          dispatch({ type: FETCH_ALERTS_SUCCESS, payload: decryptedData });
        }).catch((err) => {
          throw new Error(err);
        });
      } else {
        AlertService.fetchCustomAlerts(auth).then(alerts => {
          const { data } = alerts;
          const decryptedData = JSON.parse(decryptAES(data, encryptionKey)).text;
          dispatch({ type: FETCH_ALERTS_SUCCESS, payload: decryptedData });
        }).catch((err) => {
          throw new Error(err);
        });
      }
    }
  }, [auth, ui]);

  useEffect(() => {
    if (!allowedChains.includes(window.ethereum.chainId)) {
      dispatch({ type: CHAIN_NOT_ALLOWED });
    }
  }, []);

  // useEffect(() => {
  //   Sentry.init({
  //     dsn: "https://877b70cc4bd1424e8c54cebd3b166868@o4505200781819904.ingest.sentry.io/4505200782934016",
  //     integrations: [new Sentry.BrowserTracing()],
  //     // Performance Monitoring
  //     tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  //   });
  // }, []);

  useEffect(() => {
    return () => {
      try {
        window.ethereum.on('accountsChanged', accountWasChanged);
        window.ethereum.on('chainChanged', chainWasChanged);
      } catch (error) {
        console.error('You need to install Metamask!', error);
      }
    }
  }, [accountWasChanged, chainWasChanged]);

  const handleSettingsClick = () => {
    dispatch({ type: UPDATE_ACTIVE_MODAL, payload: ui.activeModal === 'profileSettings' ? null : 'profileSettings' });
  }

  const handlePreviewMailClick = () => {
    dispatch({ type: UPDATE_ACTIVE_MODAL, payload: ui.activeModal === 'previewMail' ? null : 'previewMail' });
  }

  return (
    <Router>
      <NotificationCenter {...props} />
      <Routes>
        {routes.map((route) => <Route path={route.path} element={<route.component {...props} />} />)}
      </Routes>
      {ui.activeModal === 'profileSettings' && (
        <Modal
          setIsOpen={handleSettingsClick}
          actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
          modalHeader="Profile Settings"
          modalContent={<ProfileSettings setIsOpen={handleSettingsClick} auth={auth} />}
          additionalClass="profile-settings-modal"
        />
      )}
      {ui.activeModal === 'previewMail' && (
        <Modal
          setIsOpen={handlePreviewMailClick}
          actionLabel={`${<FontAwesomeIcon icon={faPlus} style={{ color: '#F1EBFF70' }} />} Add`}
          modalHeader="Preview Mail"
          modalContent={<PreviewMail setIsOpen={handlePreviewMailClick} ui={ui} />}
          additionalClass="profile-settings-modal"
        />
      )}
      <div className='ciphermed-root' />
    </Router>
  );
}

const mapStateToProps = (state) => ({
  devices: state.deviceReducer,
  ui: state.uiReducer,
  auth: state.authReducer,
  alerts: state.alertReducer,
  report: state.reportReducer
});

export default compose(
  connect(mapStateToProps),
)(App);
